function hideSimplyDropdownMenus() {
    $('[simply-dropdown-menu-list]').isHidden();
    $('body').removeClass('is-with-dropdown-open');
    $('[simply-dropdown-menu-button]').removeClass('is-expanded').removeClass('is-current');
    $('[simply-dropdown-menu-chevron]').removeClass('is-expanded').removeClass('is-current');
    $('[simply-dropdown-menu-list]').removeClass('is-expanded').removeClass('is-current');
    $(document).trigger('hide-dropdown-menus');
    //console.log("Hide dropdowns menus...");
}

function initSimplyDropdownMenus() {
    $.On('click', '[simply-dropdown-menu-button]', function() {
        var thisDropdown = $(this).closest('[simply-dropdown-menu]');
        var dropdownList = thisDropdown.find('[simply-dropdown-menu-list]').first();
        var dropdownButton = thisDropdown.find('[simply-dropdown-menu-button]');
        var allOtherDropdowns = $('[simply-dropdown-menu]').not(thisDropdown);
        var allOtherDropdownLists = allOtherDropdowns.find('[simply-dropdown-menu-list]');
        var allOtherDropdownButtons = allOtherDropdowns.find('[simply-dropdown-menu-button]');
        allOtherDropdownLists.addClass('is-hidden');
        allOtherDropdownLists.removeClass('is-expanded').removeClass('is-current');
        allOtherDropdownButtons.removeClass('is-expanded').removeClass('is-current');
        dropdownButton.toggleClass('is-expanded').toggleClass('is-current');
        dropdownButton.find('[simply-dropdown-menu-chevron]').toggleClass('is-expanded');
        dropdownList.toggleClass('is-hidden');
        $('body').toggleClass('is-with-dropdown-open'); //needed to add cursor pointer on iOS
    });

    $.On('click', function(e) {
        //if clicked outside the dropdown button and content, close it
        if ($(event.target).closest("[simply-dropdown-menu-button]").length === 0 && $(event.target).closest('[simply-dropdown-menu-list]').length === 0) {
            hideSimplyDropdownMenus();
        }
    });

    $.On('click', '[simply-dropdown-menu-item]', function() {
      var thisDropdown = $(this).closest('[simply-dropdown-menu]');
      var dropdownList = thisDropdown.find('[simply-dropdown-menu-list]');
      dropdownList.addClass('is-hidden').removeClass('is-expanded').removeClass('is-current');
    });
}

$.On('preloadingComplete', function() { //need to wait for all the ajax to load
    hideSimplyDropdownMenus();
    initSimplyDropdownMenus();
});



// Popover dropdowns - the same as dropdown menus but they do not disappear 
// when opening another popover and they can have other dropdown menus inside
function hideSimplyPopovers() {
    $('[simply-popover-dropdown-list]').isHidden();
    $('body').removeClass('is-with-dropdown-open');
    $('[simply-popover-dropdown-button]').removeClass('is-expanded').removeClass('is-current');
    $('[simply-popover-dropdown-chevron]').removeClass('is-expanded').removeClass('is-current');
    $('[simply-popover-dropdown-list]').removeClass('is-expanded').removeClass('is-current');
    $(document).trigger('hide-dropdown-menus');
    //console.log("Hide popover dropdowns...");
}

function initSimplyPopoverDropdowns() {
    $.On('click', '[simply-popover-dropdown-button]', function() {
        var thisDropdown = $(this).closest('[simply-popover-dropdown]');
        var dropdownList = thisDropdown.find('[simply-popover-dropdown-list]').first();
        var dropdownButton = thisDropdown.find('[simply-popover-dropdown-button]');
        var allOtherDropdowns = $('[simply-popover-dropdown]').not(thisDropdown);
        var allOtherDropdownLists = allOtherDropdowns.find('[simply-popover-dropdown-list]');
        var allOtherDropdownButtons = allOtherDropdowns.find('[simply-popover-dropdown-button]');
        allOtherDropdownLists.addClass('is-hidden');
        allOtherDropdownLists.removeClass('is-expanded').removeClass('is-current');
        allOtherDropdownButtons.removeClass('is-expanded').removeClass('is-current');
        dropdownButton.toggleClass('is-expanded').toggleClass('is-current');
        dropdownButton.find('[simply-popover-dropdown-chevron]').toggleClass('is-expanded');
        dropdownList.toggleClass('is-hidden');
        $('body').toggleClass('is-with-dropdown-open'); //needed to add cursor pointer on iOS
    });

    $.On('click', function(e) {
        //if clicked outside the dropdown button and content, close it
        if ($(event.target).closest("[simply-popover-dropdown-button]").length === 0 && $(event.target).closest('[simply-popover-dropdown-list]').length === 0) {
            hideSimplyPopovers();
        }
    });

    $.On('click', '[simply-popover-dropdown-item]', function() {
      var thisDropdown = $(this).closest('[simply-popover-dropdown]');
      var dropdownList = thisDropdown.find('[simply-popover-dropdown-list]');
      dropdownList.addClass('is-hidden').removeClass('is-expanded').removeClass('is-current');
    });
}

$.On('preloadingComplete', function() { //need to wait for all the ajax to load
    hideSimplyPopovers();
    initSimplyPopoverDropdowns();
});