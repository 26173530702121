$.On('preloadingComplete', function() { //need to wait for all the ajax to load

    //Each checkbox state is stored in reactivelocalstorage
    $.On('click', '[simply-toggle-switch]', function(event) {
      var paramToChange = $(this).attr('simply-toggle-switch');
      var valueToSet;
      if (State.getParam(paramToChange) == 'true') {
        valueToSet = 'false';
      } else {
        valueToSet = 'true';
      }

      State.setParam(paramToChange, valueToSet );
    });

    $('[simply-toggle-switch]').each(function() {
        var paramToChange = $(this).attr('simply-toggle-switch');

        //default state is the Webflow default state based on the class
        var $thisToggle = $(this); 
        var $thisHandle = $(this).find('[simply-toggle-switch-handle]');; 
        var $thisCircle = $(this).find('[simply-toggle-switch-circle]');

        State.onParamChange(paramToChange, function(value) {
            //fallback for weid autofill behaviour
            if (value !== "false" && value !== "true") {value = "false";}

            if (value == 'true') {
              $thisToggle.addClass('is-activated');
              $thisHandle.addClass('is-activated');
              $thisCircle.addClass('is-activated');
            } else if (value == 'false') {
              $thisToggle .removeClass('is-activated');
              $thisHandle.removeClass('is-activated');
              $thisCircle.removeClass('is-activated');
            }

            $thisToggle.find('[simply-toggle-switch-label="enabled"]').isShownWhen(value === 'true');
            $thisToggle.find('[simply-toggle-switch-label="disabled"]').isShownWhen(value === 'false');
        });
    });

});
