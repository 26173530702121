$.On.touchstartOrClick = function(selector, eventHandlerFunction) {
	$.On('click touchstart', selector, function(event) {

		var clickedElement = $(event.currentTarget);

		if (typeof eventHandlerFunction === 'function') {
			if (event.type === 'touchstart') {
			    $(document).off('click', selector);
		       	eventHandlerFunction(event);
		    	$.On('click', selector, function(event) {
		    		event.stopPropagation();
		    		event.preventDefault();
		    		return false;
		    	});
			} else {
				eventHandlerFunction(event);
			}
		} else {
			console.error('onTouchstartOrClick event handler is not a function');
		}
	});
};

$.On.touchendOrClick = function(selector, eventHandlerFunction) {
	$.On('click touchend', selector, function(event) {

		var clickedElement = $(event.currentTarget);

		if (typeof eventHandlerFunction === 'function') {
			if (event.type === 'touchend') {
			    $(document).off('click', selector);
		       	eventHandlerFunction(event);
		    	$.On('click', selector, function(event) {
		    		event.stopPropagation();
		    		event.preventDefault();
		    		return false;
		    	});
			} else {
				eventHandlerFunction(event);
			}
		} else {
			console.error('touchendOrClick event handler is not a function');
		}
	});
};

$.On.touchendOrMouseup = function(selector, eventHandlerFunction) {
	$.On('mouseup touchend', selector, function(event) {

		var clickedElement = $(event.currentTarget);

		if (typeof eventHandlerFunction === 'function') {
			if (event.type === 'touchend') {
			    $(document).off('mouseup', selector);
		       	eventHandlerFunction(event);
		    	$.On('mouseup', selector, function(event) {
		    		event.stopPropagation();
		    		event.preventDefault();
		    		return false;
		    	});
			} else {
				eventHandlerFunction(event);
			}
		} else {
			console.error('touchendOrMouseup event handler is not a function');
		}
	});
};

$.On.touchstartOrMousedown = function(selector, eventHandlerFunction) {
	$.On('mousedown touchstart', selector, function(event) {

		var clickedElement = $(event.currentTarget);

		if (typeof eventHandlerFunction === 'function') {
			if (event.type === 'touchstart') {
			    $(document).off('mousedown', selector);
		       	eventHandlerFunction(event);
		    	$.On('mousedown', selector, function(event) {
		    		event.stopPropagation();
		    		event.preventDefault();
		    		return false;
		    	});
			} else {
				eventHandlerFunction(event);
			}
		} else {
			console.error('touchstartOrMousedown event handler is not a function');
		}
	});
};