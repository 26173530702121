$.On('preloadingComplete', function() {
  var tooltipsterTrigger = 'custom';

  var tooltipsterTriggerOpen = {
    mouseenter: true,
    touchstart: true,
    tap: true,
    click: true
  };

  var tooltipsterTriggerClose = {
    mouseleave: true,
    scroll: true,
    tap: true
  };

  $('[protoflow-tooltip]').parent().not('.tooltipstered').each(function() {
    var tooltipPosition = $(this).find('[protoflow-tooltip__position]').text().toLowerCase().trim();
    var tooltipContent = $(this).find('[protoflow-tooltip__text]').html();
    var tooltipDelay = $(this).find('[protoflow-tooltip__delay]').text().trim();
    var tooltipMaxWidth = $(this).find('[protoflow-tooltip__max-width]').text().trim();
    $(this).tooltipster({
      position: tooltipPosition,
      trigger: 'custom',
      triggerOpen: tooltipsterTriggerOpen,
      triggerClose: tooltipsterTriggerClose,
      hideOnClick: false,
      animation: 'fade',
      delay: parseInt(tooltipDelay),
      animationDuration: 150,
      maxWidth: parseInt(tooltipMaxWidth) || 280,
      theme: 'tooltipster-borderless',
      restoration: 'current',
      contentAsHTML: true,
      content: tooltipContent
    });
  });
});