$(document).on('preloadingComplete', function() {
  $('[protoflow-on-click-go-back]').each(function() {
    var thisParent = $(this).parent();
    thisParent.attr('protoflow-on-click-go-back__parent', '');
  });
});

$.On('click', '[protoflow-on-click-go-back__parent]', function(e) {
  e.stopPropagation();
  window.history.back();
});