$(document).on('preloadingComplete', function() {
  $('[protoflow-on-click-set-state]').each(function() {
    var thisParent = $(this).parent();
    thisParent.attr('protoflow-on-click-set-state__parent', '');
  });
});

$.On('click', '[protoflow-on-click-set-state__parent]', function(e) {
  e.stopPropagation();
  $(this).find('[protoflow-on-click-set-state]').each(function() {
    var key = $(this).find('[protoflow-on-click-set-state__key]').text().trim().split(' ').join('-');
    var value = $(this).find('[protoflow-on-click-set-state__value]').text().trim();
    var delayTime = $(this).find('[protoflow-on-click-set-state__delay]').text().trim();
    var createHistoryState = $(this).find('[protoflow-on-click-set-state__create-history-state]').text().trim().toLowerCase();
    if (createHistoryState === 'true') {
      createHistoryState = true;
    } else {
      createHistoryState = false;
    }
    if (isEmpty(delayTime) || !$.isNumeric(delayTime)) {
      delayTime = 0;
    }
    delay(delayTime, function() {
      if (isNotEmpty(key) && isNotEmpty(value)) {
        StateURL.set(key, value, {doNotCreateHistoryState: !createHistoryState});
      }
    });
  });
});