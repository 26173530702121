function handleProtoflowAttributeToParent() {
  $('[protoflow-attribute-of-parent]').each(function() {
    var key = $(this).find('[protoflow-attribute-of-parent__key]').text();
    var value = $(this).find('[protoflow-attribute-of-parent__value]').text();
    if (isEmpty(value)) {value = ''};
    if (isNotEmpty(key)) {
      $(this).parent().attr(key, value);
    }
  });
}

function handleProtoflowAttributeToPreviousSibling() {
  $('[protoflow-attribute-of-previous-sibling]').each(function() {
    var key = $(this).find('[protoflow-attribute-of-previous-sibling__key]').text();
    var value = $(this).find('[protoflow-attribute-of-previous-sibling__value]').text();
    if (isEmpty(value)) {value = ''};
    if (isNotEmpty(key)) {
      $(this).prevAll().not('[protoflow-attribute-of-previous-sibling]').first().attr(key, value);
    }
  });
}