$.On('click', '[action-collapse-next-div]', function() {
	$(this).next().toggleClass('is-hidden');
	$(this).find('[icon-to-rotate-when-expanding]').toggleClass('is-expanded');
});

$.On('click', '[action-show-next-section-and-hide-button]', function() {
	$(this).next().toggleClass('is-hidden');
	$(this).addClass('is-hidden');
});

function initSimplyCollapsibleSections() {
    $.On('click', '[simply-collapsible-section-header]', function() {
        var param = $(this).closest('[simply-collapsible-section]').attr('simply-collapsible-section');
        State.toggle(param, 'open', 'closed');
    });

    $('[simply-collapsible-section]').each(function() {
      var thisSection = $(this).closest('[simply-collapsible-section]');
      var thisChevron = thisSection.find('[simply-collapsible-section-chevron]');
      var thisHeader = thisSection.find('[simply-collapsible-section-header]');
      var thisContent = thisSection.find('[simply-collapsible-section-content]')

      var param = $(this).attr('simply-collapsible-section');
      State.onChange(param, function(value) {
        thisContent.isShownWhen(value === 'open');
        thisChevron.addClassWhen(value === 'open', 'is-expanded');
        thisHeader.addClassWhen(value === 'open', 'is-expanded');
      });
    });

}

$.On('preloadingComplete', function() { //need to wait for all the ajax to load
    initSimplyCollapsibleSections();
});