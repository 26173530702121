function initInteractionElement__addClassWhen() {
  $('[protoflow-add-class-when]').each(function() {
      var parent = $(this).parent();
      var key = $(this).find('[protoflow-add-class-when__key]').text().trim().split(' ').join('-');
      var valueToMatch = $(this).find('[protoflow-add-class-when__value]').text().trim();
      var classToAdd = $(this).find('[protoflow-add-class-when__class-name]').text().trim().split(' ').join('-').toLowerCase();
      var delayTimeAdding = $(this).find('[protoflow-add-class-when__delay-adding]').text().trim().split(' ').join('-').toLowerCase() || 0;
      var delayTimeRemoving = $(this).find('[protoflow-add-class-when__delay-removing]').text().trim().split(' ').join('-').toLowerCase() || 0;

      if (isNotEmpty(key) && isNotEmpty(valueToMatch) && isNotEmpty(classToAdd)) {
        StateURL.onChange(key, function(value) {
          var expression = value == valueToMatch;
          if (!!expression) {
            setTimeout(function() {
              parent.addClass(classToAdd);
            }, delayTimeAdding);
          } else {
            setTimeout(function() {
              parent.removeClass(classToAdd);
            }, delayTimeRemoving);
          }
        });
      }
  });
}