$(document).on('preloadingComplete', function() {
  $('[protoflow-text-input-bind-to-state]').each(function() {
      var parent = $(this).parent();
      var input = parent.find('input[type="text"], input[type="textarea"]');
      var key = parent.find('[protoflow-text-input-bind-to-state__key]').text().trim().split(' ').join('-');
      input.attr('protoflow-text-input-bind-to-state__input', key);
      var updateOnInput = parent.find('[protoflow-text-input-bind-to-state__update-on-input]').text().trim().toLowerCase();
      if (updateOnInput === 'true') {
        input.attr('update-on-input', 'true');
      } else {
        input.removeAttr('update-on-input');
      }

      StateURL.onChange(key, function(value) {
        input.val(value);
      });

      var defaultValue = parent.find('[protoflow-text-input-bind-to-state__default-value]').text();
      if (isNotEmpty(defaultValue)) {
        StateURL.setDefault(key, defaultValue);
      }
  });

  function protoflowTriggerBindedTextInput($input) {
    var thisInputValue = $input.val();
    var key = $input.attr('protoflow-text-input-bind-to-state__input');
    if (isNotEmpty(thisInputValue)) {
      StateURL.set(key, thisInputValue, {doNotCreateHistoryState: true});
    } else if (isEmpty(thisInputValue)) {
      StateURL.remove(key, {doNotCreateHistoryState: true});
    }
  }

  $.On('blur', '[protoflow-text-input-bind-to-state__input]', function(event) {
    protoflowTriggerBindedTextInput($(this));
  });

  $.On('input', '[protoflow-text-input-bind-to-state__input][update-on-input]', function(event) {
    protoflowTriggerBindedTextInput($(this));
  });
});


