function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]};

File.prototype.toObject = function () {
  return Object({
    lastModified: parseInt(this.lastModified),
    lastModifiedDate: String(this.lastModifiedDate),
    name: String(this.name),
    size: parseInt(this.size),
    type: String(this.type)
  })
}

FileList.prototype.toArray = function () {
  return Array.from(this).map(function (file) {
    return file.toObject()
  })
}

$.On('preloadingComplete', function() {
  $('[simply-file-input]').each(function() {
    var param = $(this).attr('simply-file-input');
    var htmlInput = $(this).find('[simply-file-input-html-input]');



    State.setDefault(param, []);

    $.On('change', htmlInput, function() {
      //TODO in the future use uploaded files data
      //State.set(param, htmlInput.val());
      var filesFromInput = htmlInput[0].files.toArray();
      var filesPrevious = State.get(param);
      if (isEmpty(filesPrevious)) {
        filesPrevious = [];
      }

      $.each(filesFromInput, function(index, arrValue) {
        arrValue.status = 'not-uploaded-yet';
        arrValue.id = newId();
        arrValue.alreadyHandled = false;
        //TODO validate size etc.
      });

      var previousAndNewFiles = filesPrevious.concat(filesFromInput);
      State.set(param, previousAndNewFiles);

      //TODO remove this after tests
      // State.set(param, [
      //   {
      //     name: 'demofile.pdf',
      //     size: '2.34 MB',
      //     status: 'in-progress',
      //   },
      //   {
      //     name: 'demofile.pdf',
      //     size: '2.34 MB',
      //     status: 'in-progress',
      //   },
      //   {
      //     name: 'demofile.pdf',
      //     size: '2.34 MB',
      //     status: 'failed',
      //   },
      // ]);



      // delay(3000, function() {
      //   State.set(param, [
      //     {
      //       name: 'demofile.pdf',
      //       size: '2.34 MB',
      //       status: 'success',
      //     },
      //     {
      //       name: 'demofile.pdf',
      //       size: '2.34 MB',
      //       status: 'success',
      //     },
      //     {
      //       name: 'demofile.pdf',
      //       size: '2.34 MB',
      //       status: 'failed',
      //     },
      //   ]);
      // });

      delay(200, function() {
        htmlInput.val('');
      });
    });

    State.bindArrayList(param, function($elementToAppend, arrayValue) {
      //console.log(arrayValue);

      $elementToAppend.find('[simply-file-input-item-state="not-uploaded-yet"]').isShownWhen(arrayValue.status === 'not-uploaded-yet');
      $elementToAppend.find('[simply-file-input-item-state="in-progress"]').isShownWhen(arrayValue.status === 'in-progress');
      $elementToAppend.find('[simply-file-input-item-state="success"]').isShownWhen(arrayValue.status === 'success' || arrayValue.status === 'uploaded');
      $elementToAppend.find('[simply-file-input-item-state="failed"]').isShownWhen(arrayValue.status === 'failed');
      $elementToAppend.find('[simply-file-input-file-name]').text(arrayValue.name)
      $elementToAppend.find('[simply-file-input-file-size]').text(formatBytes(arrayValue.size));
      $elementToAppend.find('[simply-file-input-item-success]').addClass('is-transparent');
      if (arrayValue.status == 'success') {
        $elementToAppend.find('[simply-file-input-item-success]').removeClass('is-transparent');
        setTimeout(function() {
          $elementToAppend.find('[simply-file-input-item-success]').addClass('is-transparent');
        }, 1000);
      } else if (arrayValue.status == 'uploaded') {
        $elementToAppend.find('[simply-file-input-item-success]').addClass('is-transparent');
      }
      //TODO add more details, percentage, progress bar etc.
    });
  });

  $.On('click', '[simply-file-input-select-files-zone]', function() {
    var thisInput = $(this).closest('[simply-file-input]');
    var htmlInput = thisInput.find('[simply-file-input-html-input]');
    htmlInput.click();
  });

  $.On('click', '[simply-file-input-remove-file]', function() {
    //TODO
    //Get this input param name
    //Get index of the item
    //Remove the clicked index from param value
    var thisItem = $(this).closest('[simply-file-input-item]');
    var param = $(this).closest('[simply-file-input]').attr('simply-file-input');
    var index = thisItem.index() - 1 - 1; //minus one because of repeatable clone template in the DOM //TODO change to ID
    console.log(index);
    console.log(param);
    State.removeElementFromArrayXWithIndexY(param, index);
  });
});

function simplyPrototypeFakeFileUploadForParamX(paramName) {

  //check if there are unhandled files
  filesUploadArr = State.get(paramName);

  var thereAreNotHandledFiles = false;
  $.each(filesUploadArr, function(index, arrValue) {
    if (arrValue.alreadyHandled == false) {
      thereAreNotHandledFiles = true;
    }
  });

  //fake upload of unhandled files
  if (thereAreNotHandledFiles) {
    State.set(paramName+"__all-uploads-finished", '');

    $.each(filesUploadArr, function(index, arrValue) {

      filesUploadArr[index].alreadyHandled = true;

      if (filesUploadArr[index].status === 'not-uploaded-yet') {
        filesUploadArr[index].status = 'in-progress';
        //fake error
        if (index == 2) {
          filesUploadArr[index].status = 'failed';
        } 
        State.set(paramName, filesUploadArr);

        var timer1 = setTimeout(function() {
          //console.log(filesUploadArr[index]);
          if (filesUploadArr[index].status === 'in-progress') {
            filesUploadArr[index].status = 'success';
            State.set(paramName, filesUploadArr);
          }
        }, 1500);

        var timer2 = setTimeout(function() {
          clearTimeout(timer1);
          //console.log(filesUploadArr[index].status);
          if (filesUploadArr[index].status === 'success') {
            filesUploadArr[index].status = 'uploaded';
            State.set(paramName, filesUploadArr);
          }

          if (index+1 === filesUploadArr.length) {
            checkAllUploadsStatus();
          }
        }, 3000)

      } 

    });
  }

  //check all uploaded files status and trigger state change for handling success or failure callback
  function checkAllUploadsStatus() {
    var allSuccessful = true;
    $.each(filesUploadArr, function(index, arrValue) {
      if (filesUploadArr[index].status !== 'uploaded' && filesUploadArr[index].status !== 'success') {
        allSuccessful = false;
      }
    });

    if (allSuccessful === true) {
      State.set(paramName+"__all-uploads-finished", 'all-successful');
    } else {
      State.set(paramName+"__all-uploads-finished", 'not-all-successful');
    }
  }
}

var simpyPrototypeFakeFileUploadForParamX = simplyPrototypeFakeFileUploadForParamX; //typo backwards compatibility