/* One line show or hide based on expression */
(function( $ ) {
  $.fn.isShownWithSlideWhen = function(expression) {

    var thisInstance = this;

  if (!!expression) {
    thisInstance.each(function() {
      $(this).isShownWithSlide();
    });
  } else {
    thisInstance.each(function() {
      $(this).isHiddenWithSlide();
    });
  }

    return this;
  };
}( jQuery ));

/* One line show or hide based on expression */
(function( $ ) {
  $.fn.isHiddenWithSlideWhen = function(expression) {

    var thisInstance = this;

  if (!!expression) {
    thisInstance.each(function() {
      $(this).isHiddenWithSlide();
    });
  } else {
    thisInstance.each(function() {
      $(this).isShownWithSlide();
    });
  }

    return this;
  };
}( jQuery ));

(function( $ ) {
  $.fn.isShownWithSlide = function(transitionTime) {
    transitionTime = transitionTime || 700;
    var container = this[0];
    
    if (!container.classList.contains('is-in-transition')) {

      if (container.classList.contains('is-hidden')) {
        
        container.style.maxHeight = "0px" //temporary maxHeight to prevent blinking
        container.classList.remove('is-hidden')
        container.classList.add('is-in-transition')
        container.style.overflow = "hidden"

        var height = container.scrollHeight + "px"
        container.style.height = "0px"

        setTimeout(() => {
          container.style.maxHeight = ""
          container.style.height = height
        }, 30)

        setTimeout(() => {
          container.style.height = "";
          container.classList.remove('is-in-transition')
          container.style.overflow = ""
        }, transitionTime+50);
      }
    }

    return this;
  };
}( jQuery ));

(function( $ ) {
  $.fn.isHiddenWithSlide = function(transitionTime) {
    transitionTime = transitionTime || 700;
    var container = this[0];
    
    if (!container.classList.contains('is-in-transition')) {
      if (!container.classList.contains('is-hidden')) {
        container.classList.add('is-in-transition')
        container.style.overflow = "hidden"
        var height = container.scrollHeight + "px";
        container.style.height = height;

        setTimeout(() => {
          container.style.height = "0px";
        }, 30)

        setTimeout(() => {
          container.classList.add('is-hidden');
          container.classList.remove('is-in-transition')
          container.style.height = "";
          container.style.overflow = ""
        }, transitionTime+50);
      }
    }

    return this;
  };
}( jQuery ));


// var toggleButtons = document.querySelectorAll('[js-collapsible-toggle]');
// var transitionTime = 500; //should be the same as defined in CSS

// toggleButtons.forEach((button) => {
//   bindSlide(button);
// });

// function bindSlide(button) {
//   button.addEventListener('click', () => {
//     var container = button.closest('[js-collapsible]').querySelector('[js-collapsible-area]');
//     //https://www.npmjs.com/package/element-closest-polyfill for IE11
    
//     if (!container.classList.contains('is-in-transition')) {
//       /** Slide down. */
//       if (container.classList.contains('is-hidden')) {
        
//         container.style.maxHeight = "0px" //temporary maxHeight to prevent blinking
//         container.classList.remove('is-hidden')
//         container.classList.add('is-in-transition')
//         var height = container.scrollHeight + "px"
//         container.style.height = "0px"

//         setTimeout(() => {
//           container.style.maxHeight = ""
//           container.style.height = height
//         }, 30)

//         setTimeout(() => {
//           container.style.height = "";
//           container.classList.remove('is-in-transition')
//         }, transitionTime+50);

//       /** Slide up. */
//       } else {
//         container.classList.add('is-in-transition')

//         var height = container.scrollHeight + "px";
//         container.style.height = height;

//         setTimeout(() => {
//           container.style.height = "0px";
//         }, 30)

//         setTimeout(() => {
//           container.classList.add('is-hidden');
//           container.classList.remove('is-in-transition')
//           container.style.height = "";
//         }, transitionTime+50);
//       }
//     }
//   })
// }

