function initInteractionElement__isShownWhen() {
  $('[protoflow-is-shown-when]').each(function() {
      var parent = $(this).parent();
      var key = $(this).find('[protoflow-is-shown-when__key]').text().trim().split(' ').join('-');
      var valueToMatch = $(this).find('[protoflow-is-shown-when__value]').text().trim();

      if (isNotEmpty(key) && isNotEmpty(valueToMatch)) {
        StateURL.onChange(key, function(value) {
          parent.removeClassWhen(value == valueToMatch, 'is-hidden');
          parent.removeClassWhen(value == valueToMatch,  'is-hidden-by-protoflow');
        });

        // State.onChange(key, function(value) {
        //   parent.removeClassWhen(value == valueToMatch, 'is-hidden');
        //   parent.removeClassWhen(value == valueToMatch,  'is-hidden-by-protoflow');
        // });
      }
  });
}