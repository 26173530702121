//TODO refactor for simplicity

function initSimplyTooltipster(containerToInit) {
  containerToInit = containerToInit || 'body';

  var tooltipsterTrigger = 'custom';

  var tooltipsterTriggerOpen = {
    mouseenter: true,
    touchstart: true,
    tap: true,
    click: true
  };

  var tooltipsterTriggerClose = {
    mouseleave: true,
    scroll: true,
    tap: true
  };

  var getTooltipOptions = function(position, tooltipContent, delay) {
    return {
      position: position,
      trigger: 'custom',
      triggerOpen: tooltipsterTriggerOpen,
      triggerClose: tooltipsterTriggerClose,
      hideOnClick: false,
      animation: 'fade',
      delay: parseInt(delay),
      animationDuration: 150,
      maxWidth: 300,
      theme: 'tooltipster-borderless',
      restoration: 'current',
      content: tooltipContent
    };
  };

  $(containerToInit).not('.tooltipstered').find('[simply-tooltip]:not(.tooltipstered)').each(function() {
    var tooltipPositionFromAttr = $(this).attr('simply-tooltip');
    var tooltipContent = $(this).attr('simply-tooltip-content');
    var tooltipDelay = $(this).attr('simply-tooltip-delay');
    if ($.isEmptyObject(tooltipContent)) {
      tooltipContent = $(this).attr('simply-tooltip-text');
    }
    if ($.isEmptyObject(tooltipDelay)) {
      tooltipDelay = 20;
    }
    $(this).tooltipster(getTooltipOptions(tooltipPositionFromAttr, tooltipContent, tooltipDelay));
  });

  (function( $ ) {
    $.fn.simplyAddTooltip = function(tooltipPosition, tooltipContent, tooltipDelay) {
      if ($.isEmptyObject(tooltipDelay)) {
        tooltipDelay = 20;
      }
      if ($.isEmptyObject(tooltipPosition)) {
        tooltipDelay = 'top';
      }
      $(this).tooltipster(getTooltipOptions(tooltipPosition, tooltipContent, tooltipDelay));
      return this;
    };
  }( jQuery ));

  (function( $ ) {
    $.fn.simplyDestroyTooltip = function() {
      $(this).filter('.tooltipstered').tooltipster('destroy');
      return this;
    };
  }( jQuery ));
}

$.On('preloadingComplete', function() {
  initSimplyTooltipster();
});