function formatDecimalNumber(numberToFormat, decimalNumbers) {
  if (typeof decimalNumbers === 'undefined') {
    decimalNumbers = 2;
  }
  var number = parseFloat(numberToFormat);
  var formatted = (number.toFixed(decimalNumbers)).replace('.', ',');
  return formatted;
}

function stringToDecimal(stringToFormat) {
  var number = stringToFormat.replace(',', '.');
  number = parseFloat(number);
  return number;
}

function replaceComa(stringToFormat) {
  var cleaned = stringToFormat.replace(',', '.');
  return cleaned;
}

function getPeriodTextForPredictedCost(period) {
  var textsToReturn = {
    1: 'Płaciłbyś miesięcznie',
    2: 'Płaciłbyś co 2' + String.fromCharCode(160) + 'miesiące',
    4: 'Płaciłbyś co kwartał',
    12: 'Płaciłbyś rocznie'
  }
  return textsToReturn[period] || textsToReturn[1]
}

function replaceStatementToBoolean(statement) {
  var booleanValue = {
    'yes': true,
    'no': false
  }
  return booleanValue[statement]
}

function compareValues(currentValue, newValue) {
  if (!$.isNumeric(currentValue) || !$.isNumeric(newValue)) {
    return "cannot compare";
  }

  currentValue = currentValue * 1;
  newValue = newValue * 1;
  if ( currentValue == newValue ) {
    return "equal";
  }
  else if ( newValue > currentValue ) {
    return "new-is-larger";
  }
  else if ( newValue < currentValue ) {
    return "new-is-lower";
  }
}

function arrayToString(arrayToConvert) {
  if ($.isArray(arrayToConvert)) {
    return arrayToConvert.join(' ');
  } else {
    return '';
  }
}

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

//https://stackoverflow.com/questions/4597900/checking-something-isempty-in-javascript
function isEmpty(val) {

    // test results
    //---------------
    // []        true, empty array
    // {}        true, empty object
    // null      true
    // undefined true
    // ""        true, empty string
    // ''        true, empty string
    // 0         false, number
    // true      false, boolean
    // false     false, boolean
    // Date      false
    // function  false

        if (val === undefined)
        return true;

    if (typeof (val) == 'function' || typeof (val) == 'number' || typeof (val) == 'boolean' || Object.prototype.toString.call(val) === '[object Date]')
        return false;

    if (val == null || val.length === 0)        // null or 0 length array
        return true;

    if (typeof (val) == "object") {
        // empty object

        var r = true;

        for (var f in val)
            r = false;

        return r;
    }

    return false;
}

function isNotEmpty(val) {
  return !isEmpty(val);
}

function isEmptyOrFalse(obj) {
  if (isEmpty(obj)) {
    return true 
  } else if (obj === 'false' || obj === false) {
    return true 
  } else {
    return false
  }
}

$.On('click', '[action-go-back]', function() {
  window.history.back();
});

//setting proper with of fixed top bar to show scroll bar
function getWidthOfWrapperScrollbar() {
  var bodyWidth = $('body').outerWidth();
  var wrapperWidth = $('[js-page-wrapper]').outerWidth();
  var difference = bodyWidth - wrapperWidth;

  return difference;
}

function isNumeric(val) {
  return $.isNumeric(val);
}

function isNotNumeric(val) {
  return !isNumeric(val);
}

$(document).on('preloadingComplete', function() {
  $(document).on('mouseenter', '[js-parent-for-show-on-hover]', function() {
    $(this).find('[js-show-when-hover-on-parent]').isShown();
  });
  $(document).on('mouseleave', '[js-parent-for-show-on-hover]', function() {
    $(this).find('[js-show-when-hover-on-parent]').isHidden();
  });
});

//https://stackoverflow.com/questions/6921275/is-it-possible-to-chain-settimeout-functions-in-javascript
function delay(t, fn) {
    // private instance variables
    var queue = [], self, timer;

    function schedule(t, fn) {
        timer = setTimeout(function() {
            timer = null;
            fn();

            if (queue.length) {
                var item = queue.shift();
                schedule(item.t, item.fn);
            }
        }, t);
    }
    self = {
        delay: function(t, fn) {
            // if already queuing things or running a timer,
            //   then just add to the queue
            if (queue.length || timer) {
                queue.push({fn: fn, t: t});
            } else {
                // no queue or timer yet, so schedule the timer
                schedule(t, fn);
            }
            return self;
        },
        cancel: function() {
            clearTimeout(timer);
            queue = [];
            return self;
        }
    };
    return self.delay(t, fn);
}

//debouncing that allows easy code block handling in ReactibeLocalStorage, without separate functions and scopes
debounceGlobalTimers = {};
function debounce(debounceName, wait, fn) {

  if (typeof wait === 'function') {
    fn = wait;
    wait = 100;
  }

  if (debounceGlobalTimers[debounceName]) {
    clearTimeout(debounceGlobalTimers[debounceName]);
  }

  debounceGlobalTimers[debounceName] = setTimeout(function() {
    fn();
  }, wait);

}

function roundToNextMultiple(x, multiple) {
  return Math.ceil(x/multiple)*multiple;
}

function roundToPrevMultiple(x, multiple) {
  return Math.floor(x/multiple)*multiple;
}

function randomChance() {
  return !!Math.floor(Math.random()*2);
}

function popupWindow(url, title, w, h) {
  var left = 100;
  var top = 100;
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
}

//https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
var popupWindowCenter = function popupCenter(_ref) {
    var url = _ref.url;
    var title = _ref.title;
    var w = _ref.w;
    var h = _ref.h;

    // Fixes dual-screen position                             Most browsers      Firefox
    var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var systemZoom = width / window.screen.availWidth;
    var left = (width - w) / 2 / systemZoom + dualScreenLeft;
    var top = (height - h) / 2 / systemZoom + dualScreenTop;
    var newWindow = window.open(url, title, "\n      scrollbars=yes,\n      width=" + w / systemZoom + ", \n      height=" + h / systemZoom + ", \n      top=" + top + ", \n      left=" + left + "\n      ");

    if (window.focus) newWindow.focus();
};


$.On('preloadingComplete', function() {
  $('[js-scroll-shadow]').each(function() {
    var attr = $(this).attr('js-scroll-shadow');
    var header = $('[js-scroll-shadow-header="'+attr+'"]');
    var area = $(this);
    header.addClassWhen(area.scrollTop() > 0, 'is-with-scroll-shadow');
    $(this).on('scroll', function() {
      header.addClassWhen(area.scrollTop() > 0, 'is-with-scroll-shadow');
    });
  });
});