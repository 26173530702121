//preload all views into respective containers
//until the subpages are loaded the UI is covered by loading overlay
//after they are preloaded, we retrigger the state of all components
//and fade in the UI to prevent flicker
//we can define for what specific events we wait until we show the UI,
//for example we may want to preload not only subpages, but additional promo modals etc.


function recursivelyPreloadElements() {
	var preloadMissingElements = function() {
		var elementsThatWillBePreloaded = $('[preload-from]').not('[preloading-started]').not('[preloading-done]');
		//mark all elements that will be preloaded
		elementsThatWillBePreloaded.attr('preloading-started', 'true');

		elementsThatWillBePreloaded.each(function() {
			var elemToLoad = $(this).attr('preload-from');
			var $this = $(this);
			var loadURL = window.location.origin+'/'+elemToLoad;
			var loadElement = "";
			if (!elemToLoad.includes('[')) {
				loadElement = " .content-to-load";
			}
			//console.log(loadElement);
			$this.load(loadURL + loadElement, function() {
				$this.attr('preloading-done', 'true');

				//if loaded element first class is the same as parent we want to remove the doubled wrapper

				var firstClassOfParent = $this.attr("class").split(/\s+/)[0];
				if ($this.children().length === 0) {
					console.log($this);
					console.log("Preloading failed - your child element probably doesn't have a correct attribute")
				} else {
					var firstClassOfLoaded = $this.children().first().attr("class").split(/\s+/)[0];
					if (firstClassOfParent === firstClassOfLoaded && $this.children().length === 1) {
						$this.children().children().unwrap();
					}
					//TODO this is not the best pattern, hard to document
				}

				checkIfEverythingIsPreloaded();
			});
		});
	};

	var checkIfEverythingIsPreloaded = function() {
		//check if there are no elements that has not yet been started preloading
		var numberOfUnitialisedElements = $('[preload-from]').not('[preloading-started]').not('[preloading-done]').length;
		var numberOfInProgressElements = $('[preload-from][preloading-started]').not('[preloading-done]').length;

		if (numberOfUnitialisedElements === 0 && numberOfInProgressElements === 0) {
			$(document).trigger('preloadedElementsReady');
		} else if (numberOfUnitialisedElements === 0 && numberOfInProgressElements > 0) {
			//console.log('Preloading still in progress...');
			//do nothing because other elements will continue recursive preloading
		} else if (numberOfUnitialisedElements > 0){
			console.log('Noticed elements to preload...');
			preloadMissingElements(); //rerun the checking function
		}
	};

	checkIfEverythingIsPreloaded();
}

function initInteractionElements() {
	handleProtoflowAttributeToParent();
	handleProtoflowAttributeToPreviousSibling();
	initInteractionElement__addClassWhen();
	initInteractionElement__isShownWhen();
}

function initTheUIAfterPreloading() {
	initInteractionElements();
	$(document).trigger('preloadingComplete');
	setTimeout(function() {
		StateURL.retriggerOnParamChangeForAll();
	}, 0);
	setTimeout(function() {
		State.retriggerOnParamChangeForAll();
	}, 0);	
	setTimeout(function() {
		if ($('.initial-load-overlay').length > 0) {
			$('.initial-load-overlay').fadeOutAndHide(500);
		}
	}, 0);
}

function waitForInitialAjaxLoadingToFinishThenShowUI(eventsToWaitFor, callbackFunction) {

	var numberOfEventsThatHappened = 0;

	$.each(eventsToWaitFor, function(index, value) {
		$(document).one(value, function() {
			//TODO refactor so that it checks if the specific events happened, not number of elements
			numberOfEventsThatHappened = numberOfEventsThatHappened + 1;
			if (numberOfEventsThatHappened === eventsToWaitFor.length) {
				if (typeof callbackFunction === 'function') { callbackFunction(); }
				console.log('Preloading complete');
			}
		});
	});
}

var preloadingComplete = false;
waitForInitialAjaxLoadingToFinishThenShowUI([
	// reserved place in case we need to wait for more events
	'preloadedElementsReady'
], function() {
	$(document).ready(function() {
		if (preloadingComplete === false) {
			initTheUIAfterPreloading();
			preloadingComplete = true;
		}
	});
});

recursivelyPreloadElements();

