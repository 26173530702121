function hideSimplyDropdowns() {
    $('[simply-select-dropdown-list]').isHidden();
    $(document).trigger('hide-simply-dropdowns');
    //console.log("Hide select dropdowns...");
}

function bindSimplySelectDropdownsToStateBasedOnItsAttributes() {
    $('[simply-select-dropdown]').not('[simply-binded]').each(function() {
        var paramToChange = $(this).attr('simply-select-dropdown');

        //default state is the first from the dropdown chosen-values options
        var firstAvailableChoice = $(this).find('[chosen-value]').first().attr('chosen-value');
        State.setDefaultParam(paramToChange, firstAvailableChoice );

        State.onParamChange(paramToChange, function(value) {
            var relDropdown = $('[simply-select-dropdown="'+paramToChange+'"]');
            var chosenItem = relDropdown.find('[chosen-value="'+value+'"]');
            var otherNotChosenItems = relDropdown.find('[chosen-value]').not(chosenItem);
            chosenItem.removeClass('is-hidden');
            otherNotChosenItems.addClass('is-hidden');
            var selectedChoice = relDropdown.find('[choice-value="'+value+'"]').addClass('is-selected');
            var otherChoices = relDropdown.find('[choice-value]').not(selectedChoice);
            otherChoices.removeClass('is-selected');
        });

        $(this).attr('simply-binded', '');
    });
}

function initSimplySelectDropdownButtons() {
    $.On('click', '[simply-select-dropdown-buton],[simply-select-dropdown-button]', function() {
        var thisDropdown = $(this).closest('[simply-select-dropdown]');
        var dropdownList = thisDropdown.find('[simply-select-dropdown-list]');
        var allOtherDropdownLists = $('[simply-select-dropdown]').not(thisDropdown).find('[simply-select-dropdown-list]');
        allOtherDropdownLists.addClass('is-hidden');
        dropdownList.toggleClass('is-hidden');
        thisDropdownSearch = thisDropdown.find('[simply-select-dropdown-search-input]');
        thisDropdownSearch.focus();
        $('body').toggleClass('is-with-dropdown-open');
    });

    $.On('click', function(e) {
        //if clicked outside the dropdown button and content, close it
        if ($(event.target).closest("[simply-select-dropdown-buton]").length === 0 && $(event.target).closest('[simply-select-dropdown-list]').length === 0) {
            hideSimplyDropdowns();
        }
    });

    $.On('click', '[choice-value]', function() {
        var valueToSet = $(this).attr('choice-value');
        var paramToSet = $(this).closest('[simply-select-dropdown]').attr('simply-select-dropdown');
        //console.log(isNotEmpty($(this).attr('do-not-select-on-click')));
        if (isEmpty($(this).attr('do-not-select-on-click'))) {
            State.setParam(paramToSet, valueToSet);
        }
        $(this).closest('[simply-select-dropdown]').find('.select-dropdown__list.w-dropdown-list').removeClass('w--open');
        hideSimplyDropdowns();
    });

    //search in dropdown - simple frontend filtering
    $('[simply-select-dropdown-search-input]').each(function() {
        var thisDropdownParam = $(this).closestElementAttrValue('simply-select-dropdown');
        State.set(thisDropdownParam+"__search-input", '');

        if ($(this).closest('[simply-select-dropdown-with-backend-search]').length === 0) {

            State.onChange(thisDropdownParam+"__search-input", function(value) {
                var thisDropdown = $('[simply-select-dropdown="'+thisDropdownParam+'"]');
                var searchInput = thisDropdown.find('[simply-select-dropdown-search-input]');
                var searchNoResults = thisDropdown.find('[simply-select-dropdown-search-no-results]');

                searchInput.val(value); 

                //store lowercase search string as attribute
                thisDropdown.find('[choice-value]').each(function() {
                    var thisText = $(this).text().trim().toLowerCase();
                    $(this).attr('simply-search-string', thisText);
                });

                if (isNotEmpty(value)) {
                    var searchQueryLowercase = value.toLowerCase();
                    var matchingOptions = thisDropdown.find('[simply-search-string*="'+searchQueryLowercase+'"]');
                    var notMatchingOptions = thisDropdown.find('[choice-value]').not(matchingOptions);
                    notMatchingOptions.attr('is-hidden-by-search', '');
                    matchingOptions.removeAttr('is-hidden-by-search');
                    searchNoResults.isShownWhen(matchingOptions.length === 0);
                } else {
                    thisDropdown.find('[choice-value]').removeAttr('is-hidden-by-search');
                    searchNoResults.isHidden();
                }
            });


        } else {
            State.onChange(thisDropdownParam+"__search-input", function(value) {
                var thisDropdown = $('[simply-select-dropdown="'+thisDropdownParam+'"]');
                var searchPrompt = thisDropdown.find('[simply-select-dropdown-search-prompt]');
                var searchResults = thisDropdown.find('[simply-select-dropdown-search-results]');
                var searchNoResults = thisDropdown.find('[simply-select-dropdown-search-no-results]');
                var searchSpinner = thisDropdown.find('[simply-select-dropdown-search-spinner]');
                searchPrompt.isShownWhen(isEmpty(value));
                searchResults.isShownWhen(isNotEmpty(value) && value.length <= 3);
                searchNoResults.isShownWhen(isNotEmpty(value) && value.length > 3);
                var spinnerTimer;
                searchSpinner.isShown();
                clearTimeout(spinnerTimer);
                spinnerTimer = setTimeout(function() {
                    searchSpinner.isHidden();
                }, 1000);
            });
        }
    });

    $(document).on('input', '[simply-select-dropdown-search-input]', function() {
        var thisDropdown = $(this).closest('[simply-select-dropdown]');
        var thisDropdownParam = thisDropdown.attr('simply-select-dropdown');
        State.set(thisDropdownParam+"__search-input", $(this).val());
    });


}

function removeWebflowEmptyChoiceValueItems() {
    //For faster working with Webflow we remove empty fields from Protoflow components
    $('[choice-value]').each(function() {
        var $this = $(this);
        var text = $this.text();
        var textWebflowNormalized = text.split('\n').join('').split(' ').join('').split(',').join('');
        //console.log(textWebflowNormalized);
        if (isEmpty(textWebflowNormalized)) {
          $this.remove();
        } 
    });
}

//Webflow dropdowns as select dropdown
//Each dropdown state is stored in a separate reactive local storage state
$.On('preloadingComplete', function() { //need to wait for all the ajax to load
    hideSimplyDropdowns();
    initSimplySelectDropdownButtons();
    bindSimplySelectDropdownsToStateBasedOnItsAttributes();
    removeWebflowEmptyChoiceValueItems(); 
});
