//This will bind all the input fields for the Reactive Local Storage, so that we can update rest ot the page based on this state
//either on input or on focus out

$.On('blur', '[simply-text-input]', function(event) {
    var thisAttr = $(this).attr('simply-text-input');
    State.setParam(thisAttr, $(this).val() );
});

$.On('input', '[simply-text-input][update-on-input]', function(event) {
    var thisAttr = $(this).attr('simply-text-input');
    var thisInputValue = $(this).val();
    if (typeof thisInputValue !== 'undefined' && event.target.validity.valid) {
        State.setParam(thisAttr, $(this).val() );
    } else if (isEmpty(thisInputValue)) {
        State.setParam(thisAttr, $(this).val() );
    }
});

function bindSimplyTextInputFieldsToStateBasedOnItsAttributes() {
    //for each input field existing in the html we check if there's a matching state
    $('[simply-text-input]').not('[simply-binded]').each(function() {
        var paramToChange = $(this).attr('simply-text-input');
        var clearButton = $(this).parent().parent().find('[simply-text-input-clear-button]');
        var thisInput = $(this);

        State.onParamChange(paramToChange, function(value) {
            $('[simply-text-input="'+paramToChange+'"]').val(value);
            clearButton.isShownWhen(isNotEmpty(value));
        });

        //optional declaration of value on load
        var valueToSetOnLoad = $(this).attr('simply-text-value-on-load');
        if (isNotEmpty(valueToSetOnLoad)) {
            State.set(paramToChange, valueToSetOnLoad);
        }

        if (thisInput.attr('simply-input-type') === 'date') {
            thisInput.attr('type', 'date');
        }

        $(this).attr('simply-binded', '');
    });
}

$.On('preloadingComplete', function() { //need to wait for all the ajax to load
    bindSimplyTextInputFieldsToStateBasedOnItsAttributes();
});

$.On('click', '[simply-text-input-clear-button]', function(event) {
    var thisParam = $(this).parent().parent().find('[simply-text-input]').attr('simply-text-input');
    State.setParam(thisParam, '');
});

$.On('preloadingComplete', function() {
  $('[simply-textarea-rows]').each(function() {
    var rows = $(this).attr('simply-textarea-rows');
    $(this).attr('rows', rows);
  });
});

//TODO Auto height for textarea