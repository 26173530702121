$.On('click', '[js-add-another-field]', function(e) {
  var param = $(this).closest('[js-form-section-with-add-remove-fields]').attr('js-form-section-with-add-remove-fields');
  State.set(param+'__show-additional-add-remove-field-in-form', 'show');
});

$.On('click', '[js-remove-additional-field]', function(e) {
  var param = $(this).closest('[js-form-section-with-add-remove-fields]').attr('js-form-section-with-add-remove-fields');
  State.set(param+'__show-additional-add-remove-field-in-form', 'hide');
});

$.On('preloadingComplete', function() {
  $('[js-form-section-with-add-remove-fields]').each(function() {
    var param = $(this).attr('js-form-section-with-add-remove-fields');

    State.setDefault(param+'__show-additional-add-remove-field-in-form', 'hide');
    State.onChange(param+'__show-additional-add-remove-field-in-form', function(value) {
      var thisForm = $('[js-form-section-with-add-remove-fields="'+param+'"]');
      thisForm.find('[js-additional-field]').isShownWhen(value === 'show')
    });
  });
});
