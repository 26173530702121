//helpful shortcuts for shorter code

/* One line to show or hide element selected by attribute if its value equal to specific value */
function showOnlyElementsWithAttributeXMatchingY(attributeName, valueToMatch) {
  $('['+attributeName+']').each(function() {
    var attrVal = $(this).attr(attributeName);
    $(this).isShownWhen(attrVal == valueToMatch);
  });
}

/* One line to show or hide element selected by attribute if its value equal to specific value */
function addClassToElementsWithAttributeXMatchingY(attributeName, valueToMatch, classToAdd) {
  $('['+attributeName+']').each(function() {
    var attrVal = $(this).attr(attributeName);
    $(this).addClassWhen(attrVal == valueToMatch, classToAdd);
    $(this).find('[simply-also-add-class-to-this]').addClassWhen(attrVal == valueToMatch, classToAdd);
  });
}

/* Quick one line showing or hiding element depending on reactive local storage param value */
(function( $ ) {
  $.fn.onlyShowWhenReactiveLocalStorageParamEquals = function(paramName, valueToEqual) {

    var thisInstance = this;

    State.onParamChange(paramName, function(value) {
      thisInstance.each(function() {
        $(this).isShownWhen(value == valueToEqual);
      });
    });

    return this;
  };
}( jQuery ));

//easier to read syntax for attribute selectors
function elementWithAttr(attrName, attrValue) {
  if ($.isEmptyObject(attrValue)) {
    return '['+attrName+']';
  } else {
    return '['+attrName+'="'+attrValue+'"]';
  }
}

/* Synonyms for state management libraries */
//TODO remove this
(function( $ ) {
  $.State = function(storageType) {
    storageType = storageType || 'localStorage';

    if (storageType === 'localStorage') {
      return ReactiveLocalStorage;
    } else if (storageType.toLowerCase() === 'url') {
      return QueryStringRouter;
    } else if (storageType.toLowerCase() === 'session') {
      //TODO
    }
  };
}( jQuery ));

$(document).on('click', '[js-prevent-default]', function(e) {
  e.preventDefault();
});

$(document).on('click', '[js-stop-propagation]', function(e) {
  e.stopPropagation();
});

$.On.clickElementWithAttribute('js-go-to-url', function(attrVal) {
  window.location.href = attrVal;
});

$.On.clickElementWithAttribute('js-go-to-url-in-new-tab', function(attrVal) {
  window.open(attrVal, '_blank').focus();
});

/* Get attribute value from closest element with this attrubite */
(function( $ ) {
  $.fn.closestElementAttrValue = function(attr) {

    var thisInstance = this;

    var closest = $(this).closest('['+attr+']');
    var attrValue = closest.attr(attr);

    return attrValue;
  };
}( jQuery ));

/* Adding class for odd and even elements */
(function( $ ) {
  $.fn.addClassToOddChildren = function(className) {
    var thisInstance = this;
    var oddChildren = $(this).children().odd();
    oddChildren.addClass(className);
    return this;
  };
}( jQuery ));

$.On('preloadingComplete', function() {
  $('[js-add-class-to-odd-children]').each(function() {
    var classToAdd = $(this).attr('js-add-class-to-odd-children');
    $(this).addClassToOddChildren(classToAdd);
  });
});

(function( $ ) {
  $.fn.addClassToEvenChildren = function(className) {
    var thisInstance = this;
    var evenChildren = $(this).children().even();
    evenChildren.addClass(className);
    return this;
  };
}( jQuery ));

$.On('preloadingComplete', function() {
  $('[js-add-class-to-even-children]').each(function() {
    var classToAdd = $(this).attr('js-add-class-to-even-children');
    $(this).addClassToEvenChildren(classToAdd);
  });
});

$('[simply-webflow-video-player]').each(function() {
  $thisVideo = $(this).find('video');
  $thisVideo.attr('controls', '');
  $thisVideo.css('z-index', 'auto');
});

$('[simply-webflow-video-autoplay-off]').each(function() {
  $thisVideo = $(this).find('video');
  $thisVideo.removeAttr('autoplay');
});

